export default {
  name: 'crossProducts',
  store() {
    const {
      logError,
      throwError,
      parseJson,
      findSelectedVariant,
      parseShopifyPrice,
      formatCurrency,
      parseMoneyString
    } = window.sourcherry.helpers
    let current = {}
    let mainVariantId = {id: null}

    const registerCrossProduct = (el, crossProductId, currentMainVariantId, options, mayHasClone) => {
      if (typeof crossProductId !== 'number') {
        throwError('Invalid cross product id')
      }

      const crossProduct = parseCrossProductEl(el, crossProductId)

      if (!crossProduct) {
        logError('Cross product not found')
        return
      }

      if (!crossProduct.variants?.length) {
        logError('Cross product variants not found')
        return
      }

      if (!options?.length) {
        logError('Default options not found')
        return
      }

      if (!currentMainVariantId) {
        logError('current main variant id is missing')
      }
      mainVariantId.id = currentMainVariantId

      const selectedVariant = findSelectedVariant(crossProduct.variants, options)
      if (!selectedVariant) {
        logError('No variant matches the selected options')
        return
      }

      const outOfStockOptions = findOutOfStock(selectedVariant.options, crossProduct.variants)

      let localRules = []
      const sel = `[data-prodify-cross-product-availability]`
      const rulesetEl = el.querySelector(sel)
      if (rulesetEl) {
        const rulesStr = rulesetEl.textContent
        if (typeof rulesStr === 'string' && rulesStr?.length) {
          const rulesArr = JSON.parse(rulesStr)
          if (rulesArr && Array.isArray(rulesArr)) {
            if (!rulesArr?.length) {
              console.error('invalid ruleset')
            }
            localRules = rulesArr
          }
        }
      }

      const activeRuleArr = localRules?.find((rule) => rule[0]?.includes(mainVariantId.id))
      const activeRule = activeRuleArr?.length == 2 ? activeRuleArr[1] : null

      const availableOptions = findInnerAvailable(selectedVariant.options, crossProduct.variants, undefined, activeRule)

      const customiserSettingsSel = `[data-prodify-cross-product-customiser-settings]`
      const customiserSettingsEl = el.querySelector(customiserSettingsSel)

      let customiserSettings = {}
      if (customiserSettingsEl) {
        const customiserSettingsStr = customiserSettingsEl.textContent
        if (typeof customiserSettingsStr === 'string' && customiserSettingsStr?.length) {
          const customiserSettingsArr = JSON.parse(customiserSettingsStr)
          if (customiserSettingsArr && Array.isArray(customiserSettingsArr)) {
            if (!customiserSettingsArr?.length) {
              console.error('invalid customiser option set')
            }
            const [key, value, targetVariantsArr] = customiserSettingsArr[0]

            if (key && value && targetVariantsArr?.length) {
              targetVariantsArr.forEach((vari) => {
                Object.assign(customiserSettings, { [vari]: { [key]: value } })
              })
            }
          }
        }
      }

      mutateState(crossProductId, {
        variants: crossProduct.variants,
        variant: selectedVariant,
        availableOptions,
        outOfStockOptions,
        rules: localRules,
        activeRule,
        customiserSettings
      })

      updateExtraOptionLabels(crossProductId, options, outOfStockOptions, availableOptions)
      if (mayHasClone) {
        initCloneOptions(crossProductId, options, outOfStockOptions, availableOptions)
      }

      return { options: selectedVariant.options, availableOptions, outOfStockOptions, activeRule, customiserSettings }
    }

    const updateCrossProductVariant = (crossProductId, crossProductOptions, posi, isClone) => {
      if (!crossProductId) {
        logError('ID not found')
        return
      }

      const currentProduct = current[crossProductId]
      const variants = currentProduct?.variants

      if (!crossProductOptions?.length) {
        logError('Options not found or empty')
        return
      }
      if (!variants || !variants?.length) {
        logError('Variants not found or empty')
        return
      }
      const selectedVariant = findSelectedVariant(variants, crossProductOptions)
      if (selectedVariant) {
        const outOfStockOptions = findOutOfStock(selectedVariant.options, variants, posi)
        const activeRules = currentProduct?.rules?.find((rule) => rule[0]?.includes(mainVariantId.id))
        const activeRule = activeRules?.length == 2 ? activeRules[1] : null

        const availableOptions = findInnerAvailable(selectedVariant.options, variants, undefined, activeRule)

        mutateState(crossProductId, { variant: selectedVariant, availableOptions, outOfStockOptions, activeRule })

        updateExtraOptionLabels(crossProductId, crossProductOptions, outOfStockOptions, availableOptions, isClone)

        const settings = Object.entries(currentProduct.customiserSettings).find(
          ([key, value]) => key == selectedVariant.id
        )
        if (settings) {
          const qtySetting = Object.entries(settings[1]).find(
            ([settingsKey, value]) => settingsKey == 'suggested_quantity'
          )
        }

        return {
          availableOptions,
          outOfStockOptions,
          options: selectedVariant.options,
          activeRule,
          activeVariantId: selectedVariant.id
        }
      } else {
        // TODO: handle when invalid combo leads to no variant found this case
        return { availableOptions: {}, outOfStockOptions: {}, options: [] }
      }
    }

    const findInnerAvailable = (options, variants, posi, ruleArr) => {
      if (!options.length) {
        logError('Find inner available error: missing options.')
        return
      }
      const option1 = ruleArr ?? undefined

      if (options?.length === 1) {
        return { option1, option2: [], option3: [] }
      }

      let availOpts
      if (posi == 2) {
        availOpts = variants.filter((variant) => variant.option1 == options[0] && variant.option2 == options[1])
      } else if (posi == 3) {
        availOpts = variants.filter((variant) => variant.option1 == options[0] && variant.option3 == options[2])
      } else {
        availOpts = variants.filter((variant) => variant.option1 == options[0])
      }

      const filteredOptions = availOpts.reduce(
        (acc, curr) => {
          acc.option2.add(curr.option2)
          acc.option3.add(curr.option3)
          return acc
        },
        { option2: new Set(), option3: new Set() }
      )

      return { option1, option2: Array.from(filteredOptions.option2), option3: Array.from(filteredOptions.option3) }
    }

    const findOutOfStock = (options, variants, posi) => {
      const outOfStockVaris = variants.filter((vari) => !vari.available)

      if (!outOfStockVaris.length) {
        return { option1: [], option2: [], option3: [] }
      } else {
        const outOfStockOpts = outOfStockVaris.reduce(
          (acc, curr) => {
            acc.option1.add(curr.option1)
            acc.option2.add(curr.option2)
            acc.option3.add(curr.option3)
            return acc
          },
          { option1: new Set(), option2: new Set(), option3: new Set() }
        )

        if (variants[0].options.length == 1) {
          return { option1: Array.from(outOfStockOpts.option1), option2: [], option3: [] }
        } else {
          return {
            option1: [],
            option2: Array.from(outOfStockOpts.option2),
            option3: Array.from(outOfStockOpts.option3)
          }
        }
      }
    }

    const handleLabels = (labelsArr, options, outOfStockOptions, availableOptions) => {
      labelsArr.forEach((label) => {
        const value = label.getAttribute('data-value')
        const posi = label.closest('[data-option-position]').dataset.optionPosition

        if (value && posi) {
          label.classList.toggle('checked', options.includes(value))
          label.classList.toggle('out-of-stock', outOfStockOptions[`option${posi}`]?.includes(value))
          if (availableOptions[`option${posi}`]?.length) {
            label.classList.toggle('disabled', !availableOptions[`option${posi}`].includes(value))
          }
        }
      })
    }

    const updateExtraOptionLabels = (crossProductId, options, outOfStockOptions, availableOptions, isClone) => {
      let sel = `[data-cross-product-id='${crossProductId}'][data-cross-product-options]`

      if (isClone) {
        sel += '[data-clone]'
      } else {
        sel += ':not([data-clone])'
      }
      const extraLabels = Array.from(document.querySelectorAll(sel))

      if (!extraLabels?.length) {
        logError('Extra labels not found skipping.')
        return
      }
      extraLabels.forEach((optionCtr) => {
        const labelsArr = Array.from(optionCtr.querySelectorAll('label'))
        let selectedOptions = isClone ? current[crossProductId]?.cloneOptions : options

        handleLabels(labelsArr, selectedOptions, outOfStockOptions, availableOptions)
      })
    }
    const initCloneOptions = (crossProductId, options, outOfStockOptions, availableOptions) => {
      let sel = `[data-cross-product-id='${crossProductId}'][data-cross-product-options][data-clone]`

      const cloneLabelCtrs = Array.from(document.querySelectorAll(sel))

      if (!cloneLabelCtrs?.length) {
        logError('Clone labels not found at init, skipping.')
        return
      }
      cloneLabelCtrs.forEach((cloneLabelCtr) => {
        const labelsArr = Array.from(cloneLabelCtr.querySelectorAll('label'))

        // ❗️ enable this for pre-select clone option labels
        // handleLabels(labelsArr, options, outOfStockOptions, availableOptions)
      })
    }

    const mutateState = (crossProductId, { variants, ...rest }) => {
      Object.assign(current, {
        ...current,
        [crossProductId]: {
          ...current[crossProductId],
          ...rest,
          variants: variants ?? current[crossProductId]?.variants
        }
      })
    }

    const parseCrossProductEl = (el, crossProductId) => {
      return parseJson(el, `script[type="application/json"][data-crossproduct-id='${crossProductId}']`)
    }

    const resetCrossProduct = () => {
      for (const id of Object.keys(current)) {
        mutateState(id, {
          variants: null,
          variant: null,
          availableOptions: null,
          outOfStockOptions: null,
          rules: null,
          activeRule: null
        })
      }
    }

    const updateCrossProductClone = (crossProductId, cloneOptions, cloneVariantId) => {
      mutateState(crossProductId, { cloneOptions, cloneVariantId })
    }

    const updateCustomiserPriceLabels = () => {
      const customiserMainPriceEl = document.querySelector('.customiser-main-price')
      const customiserCrossPriceEls = document.querySelectorAll('.customiser-cross-total-price')
      const customiserTotalPriceEls = document.querySelectorAll('.customiser-total-price span')
      const customiserTotalCompareAtPriceEls = document.querySelectorAll('.customiser-total-price s')

      // todo: clean this mess up
      let splittedMoneyStr
      if (customiserMainPriceEl) {
        var compare_at_price_raw = parseInt(customiserMainPriceEl.dataset.compareAtPriceRaw) / 100.0;
        if (customiserMainPriceEl.textContent) {
          splittedMoneyStr = customiserMainPriceEl?.textContent?.trim()?.match(/([^\d]*)(\d+\.?\d*)/)
        }
        if (!splittedMoneyStr?.length) {
          // console.error('Customiser price label identification issue')
          return
        }
        const amountStr = splittedMoneyStr[2].replace(',', '').replace('.', '')
        const amount = parseInt(amountStr)

        const currency = splittedMoneyStr[1]
        const y = Object.values(current).map((cp) => cp.variant?.price || 0)
        const sum = y.reduce((acc, curr) => acc + curr, 0)
        const totalCPs = parseShopifyPrice(sum)

        const targets = Array.from(customiserCrossPriceEls)
        if (targets.length && totalCPs > 0) {
          targets.forEach((target) => {
            target.textContent = formatCurrency(currency, 0)(totalCPs)
          })
        }

        const targets2 = Array.from(customiserTotalPriceEls)
        if (targets2.length) {
          const formattedTotalPrice = formatCurrency(currency, 0)(totalCPs + amount)
          targets2.forEach((target) => {
            target.textContent = formattedTotalPrice
          })
        }

        const targets3 = Array.from(customiserTotalCompareAtPriceEls)
        if (targets3.length && compare_at_price_raw > 0) {
          const formattedTotalPrice = formatCurrency(currency, 0)(totalCPs + compare_at_price_raw)
          targets3.forEach((target) => {
            if(compare_at_price_raw > amount) {
              target.textContent = formattedTotalPrice
            } else {
              target.textContent = ''
            }
          })
        }
      }
    }

    return {
      current,
      mainVariantId,
      registerCrossProduct,
      updateCrossProductVariant,
      resetCrossProduct,
      updateCustomiserPriceLabels,
      updateExtraOptionLabels,
      updateCrossProductClone
    }
  }
}
